import React from "react";
import logo from "./assets/images/logo/logo-new.png";
// import { Router } from "./router";
// import { RouterProvider, BrowserRouter } from "react-router-dom";
// import { ApolloProvider } from "@apollo/client";
// import client from "./apollo/client";
// import "@fontsource/roboto/300.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/700.css";

function App() {
  // return <RouterProvider router={router} />;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <img src={logo} alt="logo" />
    </div>
    // <ApolloProvider client={client}>
    //   <BrowserRouter>
    //     <Router />
    //   </BrowserRouter>
    // </ApolloProvider>
  );
}

export default App;
